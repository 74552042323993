import React, { useState, useEffect } from 'react';
import './banner-component.scss';
import { TranslatedBlock } from './language';


export const BannerComponent = ({backgroundPhoto, titleText}) => {  
  
    const myStyle = {
        backgroundImage: `(${backgroundPhoto})`
    };
    console.log(backgroundPhoto)
    return (
      <section className="page-header banner-css" style={{ backgroundImage: "url(" + backgroundPhoto + ")" }}>
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-12 col-md-5 risk-minimisation py-lg-0 py-5">
                  <TranslatedBlock language="english">
                    <div>
                      <h1>{titleText}</h1>
                    </div>
                  </TranslatedBlock>
                </div>
                <div className="col-12 col-md-7 patient-guide">
                  <img className="mobile-only-image" src={backgroundPhoto} alt="Personal Resources Icon" />
                </div>
              </div>
            </div>
          </section>
    
    );
  }

